import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import HeroSection from '../sections/HeroSection';
import IntroWithCountersSection from '../sections/IntroWithCountersSection';
import TextImageSection from '../sections/TextImageSection';

export const ProductFamilyTemplate = ({
  title, image, description, products
}) => (
  <div className="flex-grow-1">
    <HeroSection image={ image } heightStyle="75vh" />
    <IntroWithCountersSection title={ title } description={ description } bgColorClassName="bg-primary" fontColorClassName="text-white" lineDecorationClassName="line-decoration-white" />
    { products && products.map((product) => (
      <TextImageSection
        title={ product.title }
        description={ product.description }
        image={ product.image }
        pdfFiles={ product.pdfFiles }
        key={ product.title }
        lineDecorationClassName="line-decoration-primary"
        titleColorClassName="text-primary"
        textLg={ 6 }
        textXl={ 6 }
        imageStyle={{ height: '450px' }}
      />
    ))}
  </div>
);

ProductFamilyTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.object),
  description: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.object,
    pdfFiles: PropTypes.arrayOf(PropTypes.object)
  }))
};

ProductFamilyTemplate.defaultProps = {
  image: undefined,
  products: []
};

const ProductFamilyPage = ({ data }) => {
  const { frontmatter, fields } = data.markdownRemark;
  return (
    <Layout
      title={ frontmatter.title }
      description={ frontmatter.description }
      slug={ fields.slug }
      language={ frontmatter.language }
    >
      <ProductFamilyTemplate title={ frontmatter.title } image={ frontmatter.image } description={ frontmatter.description } products={ frontmatter.products } />
    </Layout>
  );
};

export default ProductFamilyPage;

ProductFamilyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query ProductFamilyPageByID($id: String!, $language: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { language: { eq: $language } } ) {
      id
      fields {
        slug
      }
      frontmatter {
        language
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        description
        products {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          pdfFiles {
            source {
              publicURL
            }
            downloadText
          }
        }
      }
    }
  }
`;